<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title> รับเงิน </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            <v-btn
              class="mx-3"
              fab
              dark
              color="indigo"
              v-on:click="ShowDailog()"
            >
              <v-icon dark>fa fa-plus</v-icon>
            </v-btn>
            <v-btn
              class="mx-3"
              fab
              dark
              color="indigo"
              v-on:click="$router.push('/Finance/WidenEmp/History?Type=Money')"
            >
              <v-icon dark>fa fa-history</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-data-table
          :items-per-page="-1"
          :headers="headers"
          :items="desserts"
          class="elevation-1"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn class="mx-2 red" fab dark small @click="Delete(item)">
              <v-icon>fa fa-trash-alt</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-date v-model="Money.MoneyDate"></v-date>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-autocomplete
                  dense
                  label="ประเภท"
                  placeholder="ประเภท"
                  :items="item"
                  required
                  v-model="Money.MoneyType"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="1000"
                  placeholder="0"
                  type="number"
                  v-model="Money.B1000"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="500"
                  placeholder="0"
                  v-model="Money.B500"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="100"
                  placeholder="0"
                  v-model="Money.B100"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="50"
                  placeholder="0"
                  v-model="Money.B50"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="20"
                  placeholder="0"
                  v-model="Money.B20"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  label="10"
                  placeholder="0"
                  v-model="Money.B10"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-text-field
                  label="5"
                  placeholder="0"
                  v-model="Money.B5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-text-field
                  label="2"
                  placeholder="0"
                  v-model="Money.B2"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-text-field
                  label="1"
                  placeholder="0"
                  v-model="Money.B1"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-textarea
                  label="รายละเอียด"
                  placeholder="รายละเอียด"
                  v-model="Money.MoneyRemark"
                  dense
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="dialog = false">ปิด</v-btn>
            <v-btn color="green" @click="Insert()">บันทึก</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      item: [
        { text: "เพิ่มเงิน", value: "A" },
        { text: "คืนเงิน", value: "R" },
      ],
      Money: {
        MoneyDate: "",
        B1000: "0",
        B500: "0",
        B100: "0",
        B50: "0",
        B20: "0",
        B10: "0",
        B5: "0",
        B2: "0",
        B1: "0",
        MoneyType: "A",
        MoneyRemark: "",
      },
      dialog: false,
      desserts: [],
      headers: [
        {
          text: "ประเภท",
          align: "left",
          sortable: true,
          value: "Type",
          width: 100,
        },
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "MoneyDate",
          width: 100,
        },
        {
          text: "หมายเหตุ",
          align: "left",
          sortable: true,
          value: "Money.MoneyRemark",
          width: 200,
        },
        {
          text: "1000",
          align: "left",
          sortable: true,
          value: "Money.B1000",
          width: 20,
        },
        {
          text: "500",
          align: "left",
          sortable: true,
          value: "Money.B500",
          width: 20,
        },
        {
          text: "100",
          align: "left",
          sortable: true,
          value: "Money.B100",
          width: 20,
        },
        {
          text: "50",
          align: "left",
          sortable: true,
          value: "Money.B50",
          width: 20,
        },
        {
          text: "20",
          align: "left",
          sortable: true,
          value: "Money.B20",
          width: 20,
        },
        {
          text: "10",
          align: "left",
          sortable: true,
          value: "Money.B10",
          width: 20,
        },
        {
          text: "5",
          align: "left",
          sortable: true,
          value: "Money.B5",
          width: 20,
        },
        {
          text: "2",
          align: "left",
          sortable: true,
          value: "Money.B2",
          width: 20,
        },
        {
          text: "1",
          align: "left",
          sortable: true,
          value: "Money.B1",
          width: 20,
        },
        {
          text: "รวม",
          align: "left",
          sortable: true,
          value: "Money.Total",
          width: 80,
        },
        { width: 50, text: "", value: "action", sortable: false },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select();
    });
  },
  methods: {
    Select() {
      var start = this.$store.state.Date().End;
      var end = this.$store.state.Date().End;
      axios
        .get(`/api/v1/select/Select/Money?DateStart=${start}&DateEnd=${end}`)
        .then((res) => {
          if (res.status == 200) {
            this.desserts = res.data;
          }
        });
    },
    ShowDailog() {
      this.dialog = true;
    },
    Insert() {
      axios.post("/api/v1/Insert/Insert/Money", this.Money).then((res) => {
        if (res.status == 200) {
          this.Select();
          this.Prints(res.data);
        }
      });
    },
    Prints(v) {
      axios.get(`/api/v1/FinanceMoney/pdf?Id=${v.Id}`).then((res) => {
        if (res.status == 200) {
          this.PrintJs({
            printable: res.data.base64,
            type: "pdf",
            base64: true,
          });
        }
      });
    },
    Delete(v) {
      this.PassAccept("delete", () => {
        axios
          .delete("/api/v1/delete/delete/Money?MoneyId=" + v.Money.MoneyId)
          .then((res) => {
            if (res.status == 200) {
              this.Select();
            }
          });
      });
    },
  },
};
</script>
